<template>
  <v-row>
    <v-col cols="12" md="4">
      <lookup-dominio label="Status Envio"
                      v-model="value.statusEnvio"
                      :type="lookups.statusEnvio"
                      :hidden-clear="false"/>
    </v-col>
    <v-col cols="12" md="4">
      <v-text-field label="Data Inicio"
                    dense
                    type="date"
                    v-model="value.dataIni"
                    :value="this.inicioMes"
                    :rules="[
                        this.$validators.string.required,
                  ]"/>
    </v-col>
    <v-col cols="12" md="4">
      <v-text-field label="Data Fim"
                    dense
                    type="date"
                    v-model="value.dataFim"
                    :value="this.fimMes"
                    :rules="[
                        this.$validators.string.required,
                  ]"/>
    </v-col>
  </v-row>
</template>

<script>

import {ItemDeDominio} from "../../../../api/itemsDominio";
import LookupDominio from "../../common/LookupDominio";
import OrderInput from "../../common/OrderInput";
import SearchBox from "../../common/SearchBox";
import moment from 'moment';

export default {
  name: "RelatorioLogComunicacaoForm",
  components: {
    SearchBox,
    LookupDominio,
    OrderInput
  },
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      lookups: {
        statusEnvio: ItemDeDominio.STATUS_MENSAGEM,
      },
    }
  },
  computed: {
    inicioMes: function () {
      if (typeof this.value.dataIni === "undefined"){
        this.value.dataIni = moment().startOf('month').format('YYYY-MM-DD');
      }
    },
    fimMes: function () {
      if (typeof this.value.dataFim === "undefined"){
        this.value.dataFim = moment().endOf('month').format('YYYY-MM-DD');
      }
    },
  },
}
</script>

<style scoped lang="scss">
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}


</style>